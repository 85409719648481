.photo-waterfall-container {
    overflow-x: auto; /* 允许水平滚动 */
    white-space: nowrap; /* 防止子元素换行 */
    padding: 8px 0;
    -webkit-overflow-scrolling: touch; /* 优化滚动效果 */
}

.photo-waterfall {
    display: flex;
    gap: 16px;
}

.photo-item {
    cursor: pointer;
    width: 150px; /* 根据需要调整宽度 */
    text-align: center;
    position: relative; /* 添加相对定位 */
    display: inline-block; /* 确保每个项目在同一行 */
}

.plate {
    font-size: 16px;
    margin-bottom: 8px; /* 调整车牌号和图片之间的间距 */
    display: block; /* 确保车牌号占据整行 */
}

.photo {
    width: 100%;
    height: auto;
    border-radius: 8px;
}
